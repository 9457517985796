.patent .container {
  height: 70vh !important;
  overflow: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
  margin-right: 180px;
  align-items: center;
}
