.videocall {
    height: 100vh;
    width: 100%;

    .tabs_buttions img {
        width: 80%;
        background-color: #fff;
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        margin: 20px;
        padding: 11px;
        cursor: pointer;
        border-radius: 10px;
    }

    .tabs_buttions {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main_video {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .menu_1 img {
        filter: invert(1);
        width: 50%;
        background-color: rgb(149, 84, 94);
    }

}