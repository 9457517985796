.calenter {
  padding-top: 40px;
  padding-bottom: 40px;
  height: 100%;
  overflow: scroll;
  .ant-picker-calendar-header {
    background: #504da6;
    border-radius: 10px;
  }
  .callenter_main {
    display: flex;
    justify-content: center;
  }
  .ant-picker-calendar-header {
    background: #504da6;
    border-radius: 10px;
    display: block;
    padding-left: 22px;
    padding: 18px 19px;
    margin-bottom: 30px;
  }
  .ant-picker-cell-inner.ant-picker-calendar-date {
    border: 1px solid rgb(181, 181, 181) !important;
    border-bottom: none !important;
    margin-bottom: 20px;
  }
  .callenter_main {
    display: flex;
    justify-content: center;
  }
  .ant-picker-cell-inner.ant-picker-calendar-date:hover {
    background: #504da6 !important;
    color: #fff !important;
  }
  .title_callenter {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .profile {
    display: flex;
    align-items: center;
    gap: 50px;
  }
  .doctor_details {
    border: 1px solid rgb(154, 154, 154);
    padding: 20px;
    border-radius: 10px;
  }
  .cart_time {
    padding-top: 30px;
  }
  .dates {
    display: flex;
    justify-content: space-between;
  }
  .dates {
    display: flex;
    justify-content: space-between;
  }
  .ant-picker-cell-inner.ant-picker-calendar-date:hover .events li span {
    color: #fff;
  }
  .events li {
    list-style-type: none;
  }
}
