.scrollhost {
  scrollbar-width: 6px;
  -ms-overflow-style: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #504da6;
  border-radius: 10px;
}
.scrollhost::-webkit-scrollbar {
  width: 6px;
}
